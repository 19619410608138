<template lang="pug">
//- t-card(v-on="$listeners" style="min-height:500px" )
t-window(v-bind="$attrs" v-on="$listeners")
  template(slot="header") Контакты
  template(slot="header-actions-prepend")
    v-btn.mr-1(color="grey darken-3" fab depressed x-small Zicon dark @click.stop="$emit('route','client')")
        v-icon(color="primary") mdi-account-plus

  template(slot="subheader")
    v-list
     v-list-item.mb-2
        v-autocomplete(
          v-model="input"
          append-icon=""
          prepend-inner-icon="mdi-magnify"
          flat
          Zfilled
          Zsolo-inverted
          outlined
          Zrounded
          Zdense
          clearable
          hide-details
          Zautofocus
          ref="autocomplete"
          :search-input.sync="search"
          :placeholder="$t('Поиск ...')"
          :items="items"
          item-text="name"
          return-object
          :hide-no-data="!search"
          :allow-overflow="false"
          :loading="loading"
          :disabled="loading"
          @change="change")

  v-list.py-0(Ztwo-line Zflat dense)
    //- v-list-item
        v-list-item-avatar
          v-icon mdi-plus
        v-list-item-content
          v-list-item-title Добавить клиента ...

    template(v-for='(item, index) in items')
      v-list-item
        v-list-item-avatar
          v-avatar.grey--text(color="white") ИВ
        v-list-item-content
          v-list-item-title(:class="{'font-weight-bold':item.active}") {{item.title}}
          v-list-item-subtitle.grey--text {{item.headline}} {{item.subtitle}}
        v-list-item-action
          v-icon mdi-chevron-right
        //- v-list-item-action
          v-list-item-action-text {{item.action}}
          v-badge(v-if='active' inline color="primary" content="10")
          //- .ezcar 10.000 ₸
      v-divider(v-if='index < items.length - 1' :key='index')

  //- template(slot="footer"): t-btn(@click="addClient({phone,name})") Добавить

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  data: () => ({
    selected: [2],
    input: null,
    search: null,
    loading: false,
    items: [
      {
        action: '15 мин',
        headline: '',
        subtitle: 'Молоко 2шт, Творог "Толганай", Салтанат 10шт, Хлеб 55шт, Пакет 10шт, Зубилы 1шт,',
        title: 'Иванов Иван',
      },
      {
        action: '2 час',
        headline: 'Summer BBQ',
        subtitle: 'Wish I could come, but I\'m out of town this weekend.',
        title: 'Ерик Ахметжанов',
      },
      {
        action: '6 час',
        headline: 'Oui oui',
        subtitle: 'Do you have Paris recommendations? Have you ever been?',
        title: 'Асан Оразгалиев (777) 750 65 25',
      },
      {
        action: '12 час',
        headline: 'Birthday gift',
        subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
        title: 'Ислам Шамшидинов',
      },
      {
        action: '12 час',
        headline: 'Recipe to try',
        subtitle: 'We should eat this',
        title: 'Ельдар Бахралинов',
      },
      {
        action: '12 час',
        title: 'Галия Абылгазиновна',
      },
      {
        action: '12 час',
        title: 'Арман Тарасов',
      },
    ],
  }),

  computed: {
    ...mapState({
      orders: (state) => state.tjtheme.orders,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
    change() {

    },
  },
}
</script>
<style lang="stylus"></style>
